import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/utils";
import { useFetch } from "../hooks";

export const useGetBlockedJobbers = () => useFetch("/account/blocked_jobbers");

export const usePutBlockJobber = () => {
  const queryClient = useQueryClient();

  return useMutation(({ id }) => api.put(`/users/${id}/block_jobber`), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData([`users/${id}/jobber_profile`], (oldData) => ({
        ...oldData,
        is_blocked: true,
      }));
    },
  });
};

export const usePutUnblockJobber = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => api.put(`/users/${id}/unblock_jobber`), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData([`users/${id}/jobber_profile`], (oldData) => ({
        ...oldData,
        is_blocked: false,
      }));
    },
  });
};
