import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { borderWidth, colors, radius, sizes, spacings } from "@/assets/themes";
import { Block, Carousel, ImageGallery, Modal } from "@/components";
import { ButtonBase } from "@/components/Styles/Base";
import { useBreakpoints } from "@/modules/hooks";
import RateItem from "./RateItem";
import JobberImageRateItemWrapper from "./JobberImageRateItemWrapper";

const StyledDesktopCarouselThumbnail = styled(ButtonBase)`
  border: ${(props) =>
    props.active
      ? `solid ${borderWidth.m} ${colors.primary}`
      : `solid ${borderWidth.m} ${colors.transparent}`};
  border-radius: calc(${radius.m} + ${borderWidth.m});
  img {
    width: ${sizes.size48} !important;
    height: ${sizes.size48} !important;
    border-radius: ${radius.m} !important;
    object-fit: cover !important;
  }
`;

const JobberProfileGallery = ({
  rates,
  rating_photos,
  children,
  onClick,
  ImagesContainerWrapper = ({ children }) => <>{children}</>,
}) => {
  const desktopCarouselRef = useRef();
  const imageGalleryRef = useRef();
  const breakpoints = useBreakpoints();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedPage, setSelectedPage] = React.useState(0);
  const [activePhotoPage, setActivePhotoPage] = React.useState(0);

  const ratingPhotos = useMemo(() => {
    if (!rating_photos?.length) return [];
    const tmp = rating_photos?.map((rate) =>
      rate?.photos?.map((photo) => ({
        large: photo.large,
        thumbnail: photo.thumbnail,
        rate,
      }))
    );

    return tmp.flat();
  }, [rating_photos]);

  const childrenContent =
    typeof children === "function"
      ? children({ data: ratingPhotos })
      : children;

  const [currentRate, setCurrentRate] = useState(
    ratingPhotos?.[0]?.rate || rates?.[0]
  );

  useEffect(() => {
    setCurrentRate(ratingPhotos?.[0]?.rate || rates?.[0]);
  }, [rates, ratingPhotos]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowRight") {
      desktopCarouselRef.current.goNext();
    }
    if (e.key === "ArrowLeft") {
      desktopCarouselRef.current.goPrev();
    }
  };

  useEffect(() => {
    if (isModalOpen) document.addEventListener("keydown", handleKeyDown);
    if (!isModalOpen) document.removeEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen]);

  const handleImageClick = (index) => {
    setSelectedPage(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPage(0);
    setIsModalOpen(false);
  };

  const getPreviousPhotosLength = () => {
    if (!rates?.length) return 0;

    const currentRateIndex = rates.findIndex(
      (rate) => rate?.id === currentRate?.id
    );

    return rates
      .slice(0, currentRateIndex)
      .reduce((sum, rate) => sum + (rate.photos?.length || 0), 0);
  };

  const handleCarouselChange = (_, { activePage }) => {
    setSelectedPage(activePage);
    setActivePhotoPage(activePage);

    if (rating_photos?.length) {
      setCurrentRate(ratingPhotos[activePage - 1].rate);
    }
  };

  return (
    <Block display="flex" flexWrap="wrap" gap={spacings.xs}>
      {breakpoints.get({ xs: true, md: false }) ? (
        <>
          <ImagesContainerWrapper>
            {React.Children.map(childrenContent, (child, index) => (
              <ButtonBase
                type="button"
                onClick={() => imageGalleryRef.current.open(index)}
              >
                {child}
              </ButtonBase>
            ))}
          </ImagesContainerWrapper>
          <ImageGallery
            ref={imageGalleryRef}
            renderImagesOverlay={
              currentRate
                ? () => (
                    <JobberImageRateItemWrapper>
                      <RateItem
                        id={currentRate?.id}
                        customer={currentRate.customer}
                        job_title={currentRate.job_title}
                        rate={currentRate.rate}
                        comment={currentRate.comment}
                        created_at={currentRate.created_at}
                        numberOfLines={3}
                      />
                    </JobberImageRateItemWrapper>
                  )
                : undefined
            }
          >
            {React.Children.map(childrenContent, (child) =>
              React.cloneElement(child, { hidden: true })
            )}
          </ImageGallery>
        </>
      ) : (
        <>
          <ImagesContainerWrapper>
            {React.Children.map(childrenContent, (child, index) => (
              <ButtonBase type="button" onClick={() => handleImageClick(index)}>
                {child}
              </ButtonBase>
            ))}
          </ImagesContainerWrapper>
          <Modal.Large isOpen={isModalOpen} onClose={handleCloseModal}>
            <Modal.Item.Header onClose={handleCloseModal} />
            <Modal.Item.Wrapper>
              <Block
                display="grid"
                gridCols="minmax(0, 1fr) 350px"
                gap={spacings.ml}
              >
                <Block backgroundColor={colors.backgroundLight}>
                  <Carousel
                    initialIndex={selectedPage}
                    ref={desktopCarouselRef}
                    onChange={handleCarouselChange}
                  >
                    {React.Children.map(childrenContent, (child) =>
                      React.cloneElement(child, {
                        style: {
                          objectFit: "contain",
                          height: "60vh",
                          minHeight: 400,
                          width: "100%",
                        },
                      })
                    )}
                  </Carousel>
                </Block>
                <div>
                  <RateItem
                    id={currentRate?.id}
                    customer={currentRate.customer}
                    job_title={currentRate.job_title}
                    rate={currentRate.rate}
                    onClick={onClick}
                    comment={currentRate.comment}
                    created_at={currentRate.created_at}
                  />
                  <Block
                    display="flex"
                    flexWrap="wrap"
                    marginBottom={spacings.sm}
                    gap={spacings.xxs}
                  >
                    {currentRate.photos?.map(({ thumbnail }, index) => (
                      <StyledDesktopCarouselThumbnail
                        key={`${currentRate?.id}-${index}`}
                        type="button"
                        as="button"
                        onClick={() =>
                          desktopCarouselRef.current.goTo(
                            getPreviousPhotosLength() + index
                          )
                        }
                        // active={index === activePhotoPage}
                        active={
                          index ===
                          activePhotoPage - getPreviousPhotosLength() - 1
                        }
                      >
                        <img src={thumbnail} alt={`${currentRate.job_title}`} />
                      </StyledDesktopCarouselThumbnail>
                    ))}
                  </Block>
                </div>
              </Block>
            </Modal.Item.Wrapper>
          </Modal.Large>
        </>
      )}
    </Block>
  );
};

export default JobberProfileGallery;
