import JobberProfileGallery from "@/pages/JobberProfile/JobberProfileGallery";
import { colors, radius, sizes, spacings } from "../../assets/themes";
import Block from "../Block";
import Rating from "../Rating";
import { Body14, Body16 } from "../Text";

const OfferLastRate = ({
  id,
  customer,
  job_title,
  rate,
  comment,
  created_at,
  photos,
}) => (
  <Block
    backgroundColor={colors.backgroundLight}
    padding={spacings.sm}
    borderRadius={radius.m}
  >
    <Body16
      css={`
        word-wrap: break-word;
        word-break: break-word;
      `}
    >
      "{comment}"
    </Body16>
    {photos?.length > 0 && (
      <Block
        marginTop={spacings.s}
        marginBottom={spacings.s}
        display="flex"
        alignItems="center"
        gap={spacings.s}
      >
        <JobberProfileGallery
          rates={[
            { id, customer, job_title, rate, comment, created_at, photos },
          ]}
        >
          {photos.map((item, index) => (
            <img
              key={`${item.thumbnail}-${index}-photos-item-rate`}
              src={item.thumbnail}
              alt={`${item.job_title} photos`}
              srcSet={item.large}
              style={{
                width: sizes.size48,
                height: sizes.size48,
                objectFit: "cover",
                borderRadius: radius.m,
              }}
            />
          ))}
        </JobberProfileGallery>
      </Block>
    )}
    <Block display="flex" alignItems="center" marginTop={spacings.xs}>
      <Block marginRight={spacings.xxs}>
        <Rating rate={rate} />
      </Block>

      <Body14
        color={colors.muted}
        css={`
          word-break: break-word;
          white-space: break-spaces;
        `}
      >
        {customer.first_name} • {created_at}
      </Body14>
    </Block>
  </Block>
);

export default OfferLastRate;
