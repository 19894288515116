import { useEffect, useState } from "react";
import { colors, spacings } from "@/assets/themes";
import {
  Block,
  Body14,
  Body16,
  Button,
  Icon,
  List,
  Shape,
  Toggle,
} from "@/components";
import { CardBase } from "@/components/Styles/Base";
import { BUTTON, SHAPE } from "@/components/Styles/variants";
import polyglot from "../../utils/polyglot";
import ExtendedCoverModal from "./ExtendedCoverModal";
import { YOOJO_COVER_PRICE, YOOJO_EXTENDED_COVER_PRICE } from "@/utils";

const ExtendedCover = ({ data, add, remove, disabled }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [extendedCover, setExtendedCover] = useState(
    data.extended_cover_activated
  );

  useEffect(() => {
    if (add.isError) {
      setExtendedCover(false);
    }
    if (remove.isError) {
      setExtendedCover(true);
    }
  }, [add.isError, remove.isError]);

  return (
    <Block>
      <CardBase
        flat
        css={`
          padding: ${spacings.xs} 0;
        `}
      >
        <List.Item
          divider={false}
          withGutters
          RightComponent={() => (
            <>
              {data.force_extended_cover ? (
                <Body16 color={colors.muted}>
                  {polyglot.t("common.enabled", { count: 0 })}
                </Body16>
              ) : (
                <Toggle
                  checked={extendedCover}
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.checked) {
                      add.mutate();
                    } else {
                      remove.mutate();
                    }
                    setExtendedCover(e.target.checked);
                  }}
                />
              )}
            </>
          )}
          LeftComponent={() => (
            <Shape.Medium
              shape={SHAPE.SHAPE.CIRCLE}
              backgroundColor={colors.primary}
            >
              <Icon.Large name="shield-plus-solid" />
            </Shape.Medium>
          )}
        >
          <Body16 strong>{polyglot.t("common.extended_cover")}</Body16>
          <Body14 color={colors.muted}>
            {polyglot.t("booking.yoojo_cover_description", {
              amount: polyglot.toSmartCurrency(data.extended_cover_price),
              basicCoverAmount: polyglot.toSmartCurrency(YOOJO_COVER_PRICE, {
                noDecimal: true,
              }),
              extendedCoverAmount: polyglot.toSmartCurrency(
                YOOJO_EXTENDED_COVER_PRICE,
                {
                  noDecimal: true,
                }
              ),
            })}
          </Body14>
          <Block marginTop={spacings.xs}>
            <Button.Link
              onClick={() => setModalIsOpen(true)}
              accentColor={BUTTON.ACCENT_COLOR.BODY}
              css={`
                padding: 0;
                min-height: 0;
              `}
            >
              {polyglot.t("common.find_out_more")}
            </Button.Link>
          </Block>
        </List.Item>
        <ExtendedCoverModal
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          tab={0}
        />
      </CardBase>
    </Block>
  );
};

export default ExtendedCover;
