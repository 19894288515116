import { useGesture } from "@use-gesture/react";
import {
  animate,
  AnimatePresence,
  motion,
  useMotionValue,
} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors, sizes, spacings } from "../../assets/themes";
import { useBreakpoints, useWindowSize } from "../../modules/hooks";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import Modal from "../Modal";
import { BUTTON } from "../Styles/variants";
import { Body16 } from "../Text";

const StyledNavigationWrapper = styled.div`
  ${({ left }) => left && "left : 0"};
  ${({ right }) => right && "right : 0"};
  top: 0;
  bottom: 0;
  margin: auto 0;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 9;
  margin: ${spacings.m};
  ${
    "" /* prevent user missclick outside button and trigger the close by setting a width (size of the button) and hide the button only instead of the wrapper when page limits */
  }
  cursor: default;
  width: ${sizes.size48};
`;

const StyledGalleryHeader = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${sizes.size64};
  align-items: center;
  justify-content: flex-end;
  padding: ${spacings.s};
  z-index: 10;
  background-color: ${({ solidBackground }) =>
    solidBackground ? colors.body : "transparent"};
`;

const StyledNavigationButton = styled(Button.Medium)`
  background-color: ${colors.overlayDark}!important;
  * {
    color: ${colors.white};
  }
  &:hover {
    &:after {
      background-color: ${colors.overlayDark}!important;
    }
  }
`;

const StyledPagination = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const StyledMotionImageWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
`;

const StyledImg = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  pointer-events: all;
  user-select: none;
  cursor: default;
`;

const StyledGalleryWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  cursor: zoom-out;
`;

const StyledCustomComponentWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 10;
  padding-top: ${sizes.size64};
`;

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? window.innerWidth : -window.innerWidth,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? window.innerWidth : -window.innerWidth,
    opacity: 0,
  }),
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

const ImageGalleryModal = ({
  isOpen,
  onClose,
  initialIndex = 0,
  renderImagesOverlay,
  children,
}) => {
  const DOUBLE_TAP_DELAY = 300;

  const scale = useMotionValue(1);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const breakpoints = useBreakpoints();

  const animatedWrapperRef = useRef(null);
  const imageRef = useRef(null);
  const tapTimeoutRef = useRef(null);
  const lastTapRef = useRef(0);
  const isDragging = useRef(false);
  const { width, height } = useWindowSize();
  const [childs] = useState(React.Children.toArray(children));
  const [overlayHidden, setOverlayHidden] = useState(false);
  const [wrapperConstraints, setWrapperConstraints] = useState({});
  const [[page, direction], setPage] = useState([initialIndex, 0]);
  const [imageSize, setImageSize] = useState(null);

  useEffect(() => {
    setImageSize(imageRef.current?.getBoundingClientRect());
  }, [imageRef.current]);

  const isMobile = breakpoints.get({ xs: true, md: false });
  const canNext = page < childs.length - 1;
  const canPrev = page > 0;
  const canPaginateWrapper = isMobile && scale.get() <= 1;

  const calculateDragConstraints = (currentScale) => {
    const scaledWidth = imageSize.width * currentScale;
    const scaledHeight = imageSize.height * currentScale;
    const maxX = Math.max(0, (scaledWidth - width) / 2);
    const maxY = Math.max(0, (scaledHeight - height) / 2);

    setWrapperConstraints({
      left: -maxX,
      right: maxX,
      top: -maxY,
      bottom: maxY,
    });
    return {
      left: -maxX,
      right: maxX,
      top: -maxY,
      bottom: maxY,
    };
  };

  const paginate = (newDirection) => {
    let v = page + newDirection;
    if (v < 0) {
      v = 0;
    } else if (v > childs.length - 1) {
      v = childs.length - 1;
    }
    setPage([v, newDirection]);
    requestAnimationFrame(() => {
      setOverlayHidden(false);
      x.set(0);
      y.set(0);
      scale.set(1);
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowRight") {
      paginate(1);
    }
    if (e.key === "ArrowLeft") {
      paginate(-1);
    }
  };

  useEffect(() => {
    if (imageSize) {
      scale.onChange((s) => {
        requestAnimationFrame(() => {
          const { left, right, top, bottom } = calculateDragConstraints(s);
          // Ajust X constraint
          const currentX = x.get();
          if (currentX < left) animate(x, left, { duration: 0 });
          if (currentX > right) animate(x, right, { duration: 0 });

          // Ajust Y constraint
          const currentY = y.get();
          if (currentY < top) animate(y, top, { duration: 0 });
          if (currentY > bottom) animate(y, bottom, { duration: 0 });
        });
        if (s > 1) {
          setOverlayHidden(true);
        }
      });
    }
  }, [scale, imageSize]);

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleKeyDown);
    if (!isOpen) document.removeEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [page, isOpen]);

  const getSrcFromChildrenIndex = () => childs[page]?.props?.srcSet;

  const isVideo = (src) => {
    const videoExtensions = [
      "mp4",
      "webm",
      "ogg",
      "mov",
      "avi",
      "flv",
      "mkv",
      "mpeg",
      "mpg",
      "wmv",
      "3gp",
      "m4v",
    ];
    const extension = src.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  useGesture(
    {
      onPinch: ({ offset: [s], memo = scale.get() }) => {
        if (isMobile) {
          scale.set(s);
        }
        return memo;
      },
    },
    {
      target: document.body,
      pinch: {
        scaleBounds: { min: 1, max: 5 },
        rubberband: true,
        from: [scale.get()],
      },
    }
  );

  const handleDoubleTap = () => {
    setOverlayHidden((s) => !s);
    if (scale.get() !== 1) {
      animate(scale, 1);
    } else {
      animate(scale, 2);
    }
    animate(x, 0);
    animate(y, 0);
  };

  const handleSimpleTap = () => {
    setOverlayHidden((s) => !s);
  };

  const handleTap = () => {
    if (!isDragging.current && isMobile) {
      const now = Date.now();
      const isDoubleTap = now - lastTapRef.current < DOUBLE_TAP_DELAY;

      if (isDoubleTap) {
        if (tapTimeoutRef.current) {
          clearTimeout(tapTimeoutRef.current);
          tapTimeoutRef.current = null;
        }
        handleDoubleTap();
      } else {
        tapTimeoutRef.current = setTimeout(() => {
          handleSimpleTap();
        }, DOUBLE_TAP_DELAY);
      }
      lastTapRef.current = now;
    }
  };

  useEffect(
    () => () => {
      if (tapTimeoutRef.current) {
        clearTimeout(tapTimeoutRef.current);
      }
    },
    []
  );

  const handleDragEnd = () => {
    setTimeout(() => {
      isDragging.current = false;
    }, 0);
  };

  const handleDragStart = () => {
    isDragging.current = true;
  };

  const handleWrapperDragEnd = (e, { offset, velocity }) => {
    if (scale.get() === 1) {
      const swipe = swipePower(offset.x, velocity.x);
      if (swipe < -swipeConfidenceThreshold) {
        paginate(1);
      } else if (swipe > swipeConfidenceThreshold) {
        paginate(-1);
      }
    }
    handleDragEnd();
  };

  const handleChildDragEnd = () => {
    handleDragEnd();
  };

  return (
    <Modal.FullScreen
      hideBackdrop
      isOpen={isOpen}
      onClose={onClose}
      css={`
        overflow: hidden;
        > div > div {
          padding: 0;
          background-color: ${colors.body};
        }
      `}
    >
      <StyledGalleryWrapper
        onMouseDown={() => {
          if (!isMobile && onClose) onClose();
        }}
      >
        <StyledGalleryHeader solidBackground={!overlayHidden}>
          {childs.length > 1 && (
            <StyledPagination>
              <Body16 color={colors.white} strong>
                {page + 1}/{childs.length}
              </Body16>
            </StyledPagination>
          )}
          <StyledNavigationButton
            kind={BUTTON.KIND.MINIMAL}
            shape={BUTTON.SHAPE.CIRCLE}
            // prevent mouseDownEvent parent triggering
            onMouseDown={(e) => e.stopPropagation()}
            onClick={onClose}
          >
            <Icon.Large name="times" color={colors.white} />
          </StyledNavigationButton>
        </StyledGalleryHeader>

        <AnimatePresence exitBeforeEnter initial={false} custom={direction}>
          <StyledMotionImageWrapper
            ref={animatedWrapperRef}
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate={{
              ...variants.center,
            }}
            exit="exit"
            transition={{
              x: { ease: "easeOut", duration: 0.1 },
              opacity: { duration: 0.1 },
            }}
            style={{ position: "relative" }}
            drag={canPaginateWrapper ? "x" : false}
            dragConstraints={animatedWrapperRef}
            dragElastic={1}
            onClick={(e) => {
              // Prevent default to avoid iOS Safari issues
              e.preventDefault();
              if (!isDragging.current) handleTap();
            }}
            onDragStart={handleDragStart}
            onDragEnd={handleWrapperDragEnd}
            whileTap={{ cursor: "grabbing" }}
          >
            {renderImagesOverlay && !overlayHidden && (
              <StyledCustomComponentWrapper
                onMouseDown={(e) => e.stopPropagation()}
              >
                {renderImagesOverlay(page)}
              </StyledCustomComponentWrapper>
            )}

            <motion.div
              style={{ scale, x, y }}
              ref={imageRef}
              dragConstraints={wrapperConstraints}
              drag={!canPaginateWrapper}
              dragElastic={1}
              onDragStart={handleDragStart}
              onDragEnd={handleChildDragEnd}
            >
              {isVideo(getSrcFromChildrenIndex()) ? (
                <StyledImg
                  as={motion.video}
                  controls
                  autoPlay
                  width="100%"
                  disablePictureInPicture
                  style={isMobile ? {} : { pointerEvents: "none" }}
                  controlsList="nodownload noremoteplayback disablePictureInPicture noplaybackrate"
                  // prevent mouseDownEvent parent triggering
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  <source src={getSrcFromChildrenIndex()} type="video/mp4" />
                  Your browser does not support the video tag.
                </StyledImg>
              ) : (
                <StyledImg
                  style={isMobile ? {} : { pointerEvents: "none" }}
                  src={getSrcFromChildrenIndex()}
                  // prevent mouseDownEvent parent triggering
                  onMouseDown={(e) => e.stopPropagation()}
                />
              )}
            </motion.div>
          </StyledMotionImageWrapper>
        </AnimatePresence>

        <Block display={{ xs: "none", md: "block" }}>
          <StyledNavigationWrapper
            left
            onMouseDown={(e) => e.stopPropagation()} // prevent mouseDownEvent parent triggering
          >
            {canPrev && (
              <StyledNavigationButton
                shape={BUTTON.SHAPE.CIRCLE}
                kind={BUTTON.KIND.MINIMAL}
                onClick={() => {
                  paginate(-1);
                }}
              >
                <Icon.Large name="angle-left" color={colors.white} />
              </StyledNavigationButton>
            )}
          </StyledNavigationWrapper>
        </Block>
        <Block display={{ xs: "none", md: "block" }}>
          <StyledNavigationWrapper
            right
            onMouseDown={(e) => e.stopPropagation()} // prevent mouseDownEvent parent triggering
          >
            {canNext && (
              <StyledNavigationButton
                shape={BUTTON.SHAPE.CIRCLE}
                kind={BUTTON.KIND.MINIMAL}
                onClick={() => {
                  paginate(1);
                }}
              >
                <Icon.Large name="angle-right" color={colors.white} />
              </StyledNavigationButton>
            )}
          </StyledNavigationWrapper>
        </Block>
      </StyledGalleryWrapper>
    </Modal.FullScreen>
  );
};

export default ImageGalleryModal;
