import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { borderWidth, colors, spacings } from "@/assets/themes";
import { Block, Button, ConditionnalWrapper, Icon } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import { useAskService } from "@/modules/hooks";
import polyglot from "@/utils/polyglot";
import { OFFER_PATH } from "../dashboard/config/routes";

const JobberStickyFooter = ({ sticky = true, data }) => {
  const { show } = useAskService();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offerId = queryParams.get("offer_id");

  return (
    <ConditionnalWrapper
      condition={sticky}
      wrapper={(children) => (
        <Block
          position="sticky"
          bottom="0"
          width="100%"
          backgroundColor={colors.background}
          borderTop={`solid ${borderWidth.s} ${colors.border}`}
          paddingX={spacings.m}
          paddingTop={spacings.sm}
          paddingBottom={`calc(env(safe-area-inset-bottom) + ${spacings.sm})`}
        >
          {children}
        </Block>
      )}
    >
      <Block spaceY={spacings.s}>
        {offerId && data.selectable ? (
          <Button.Large
            block
            href={OFFER_PATH.replace(":id", offerId)}
            RightComponent={() => <Icon.Large name="arrow-right" />}
          >
            {polyglot.t("job.continue_with_first_name", {
              first_name: data.jobber.first_name,
            })}
          </Button.Large>
        ) : (
          <Button.Large
            block
            onClick={() => {
              show({
                user: data.jobber,
                subcategories: data.subcategories,
              });
            }}
          >
            {data.rebooking_jobs?.length > 0
              ? polyglot.t("common.book_first_name", {
                  first_name: data.jobber.first_name,
                })
              : polyglot.t("jobs.ask_service")}
          </Button.Large>
        )}
        {data.job && (
          <Button.Large block href={data.job.url} kind={BUTTON.KIND.SECONDARY}>
            {polyglot.t("common.see_other_offers")}
          </Button.Large>
        )}
      </Block>
    </ConditionnalWrapper>
  );
};

export default JobberStickyFooter;
