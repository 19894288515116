import { Field, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import styled, { css } from "styled-components";
import { useBreakpoints } from "@/modules/hooks";
import { colors, sizes, spacings } from "../../assets/themes";
import {
  Block,
  Body14,
  Body16,
  Button,
  Divider,
  FormControl,
  H3,
  H4,
  H5,
  Icon,
  Textarea,
} from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";
import RatingPhotos from "./RatingPhotos";

const StyledRatingButton = styled(Button.Large)`
  ${({ active, rate }) => {
    const renderColor = () => {
      if (rate >= 4) return colors.warning;
      if (rate >= 3) return colors.orangered500;
      return colors.danger;
    };
    return css`
      border: none;
      background: none !important;
      transform: scale(1);
      transition: transform 0.1s ease-in-out;
      &:hover&:not(:active) {
        transform: scale(1.3);
        transition: transform 0.1s ease-in-out;
      }
      * {
        color: ${active ? renderColor() : "var(--color-gray400)"};
      }
    `;
  }}
`;

const getRatingTaglineByRate = (rate, { first_name }) => {
  if (rate >= 5) {
    return polyglot.t("rating.tagline.5");
  }
  if (rate >= 4) {
    return polyglot.t("rating.tagline.4", { first_name });
  }
  if (rate > 0) {
    return polyglot.t("rating.tagline.3");
  }
  return polyglot.t("rating.tagline.placeholder");
};

const JobberRate = ({ jobber, rate, can_add_rating_photos }) => {
  const { values, setFieldValue } = useFormikContext();
  const [privateNoteIsVisible, setPrivateNoteIsVisible] = useState(false);
  const breakpoints = useBreakpoints();

  const handleRate = (selectedRate) => {
    setPrivateNoteIsVisible(false);
    setFieldValue("rate", selectedRate);
    if (values.complaints && selectedRate >= 4) {
      setFieldValue("complaints", []);
    } else if (values.rate === 4) {
      setFieldValue("improvments", []);
    } else if (values.rate === 5) {
      setFieldValue("badge", "");
    }
  };

  return (
    <>
      {breakpoints.get({
        xs: (
          <H4 align="center">
            {polyglot.t("rating.title", {
              first_name: jobber.first_name,
            })}
          </H4>
        ),
        md: (
          <H3 align="center">
            {polyglot.t("rating.title", {
              first_name: jobber.first_name,
            })}
          </H3>
        ),
      })}
      <Block
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginY={spacings.m}
      >
        {new Array(5).fill("").map((el, index) => (
          <StyledRatingButton
            kind={BUTTON.KIND.MINIMAL}
            shape={BUTTON.SHAPE.CIRCLE}
            key={`star-rating-button-${index}`}
            onClick={() => handleRate(index + 1)}
            rate={values.rate}
            active={values.rate > index}
          >
            <Icon.XLarge name="star-solid" size={sizes.size36} />
          </StyledRatingButton>
        ))}
      </Block>
      <Block marginBottom={spacings.m}>
        <Body16
          color={colors.muted}
          align="center"
          css={`
            white-space: "pre-wrap";
          `}
        >
          {getRatingTaglineByRate(values.rate, jobber)}
        </Body16>
      </Block>
      <AnimatePresence initial={false}>
        {values.rate > 0 && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.2 }}
            style={{ overflow: "hidden" }}
          >
            <Block spaceY={{ xs: spacings.ml, md: spacings.l }}>
              {can_add_rating_photos && (
                <RatingPhotos initialSrc={rate?.photos} />
              )}
              <FormControl
                label={`${polyglot.t("rating.comment.label", {
                  first_name: jobber.first_name,
                })}`}
              >
                <Field name="comment">
                  {({ field }) => (
                    <Textarea
                      rows={2}
                      maxLength={800}
                      placeholder="Aa..."
                      {...field}
                    />
                  )}
                </Field>
              </FormControl>
            </Block>
            <Divider.Cell
              css={`
                margin-top: ${spacings.ml};
              `}
            />
            <Block marginTop={spacings.ml} marginBottom={spacings.ml}>
              <H5
                css={`
                  margin-bottom: ${spacings.s};
                `}
              >
                {polyglot.t("rating.private_note.title")}
              </H5>
              <Body14
                color={colors.muted}
                css={`
                  margin-bottom: ${spacings.s};
                `}
              >
                {polyglot.t("rating.private_note.hint", {
                  first_name: jobber.first_name,
                })}
              </Body14>
              {!(privateNoteIsVisible || values.private_note.length > 0) && (
                <Button.XSmall
                  kind="secondary"
                  LeftComponent={() => <Icon.Medium name="eye-slashed" />}
                  onClick={() => setPrivateNoteIsVisible(true)}
                >
                  {polyglot.t("rating.private_note.cta")}
                </Button.XSmall>
              )}
              {(privateNoteIsVisible || values.private_note.length > 0) && (
                <Block marginTop={spacings.m}>
                  <Field name="private_note">
                    {({ field }) => (
                      <Textarea
                        label={polyglot.t("rating.private_note.label")}
                        placeholder={polyglot.t("common.placeholder.textarea")}
                        {...field}
                      />
                    )}
                  </Field>
                </Block>
              )}
            </Block>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default JobberRate;
