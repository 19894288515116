import { useEffect, useState } from "react";
import { Body16, Button, Icon, List, Modal } from "@/components";
import polyglot from "@/utils/polyglot";

import { colors } from "@/assets/themes";
import { BUTTON } from "@/components/Styles/variants";
import { usePutBlockJobber } from "@/modules/routes/blocked-jobbers-routes";

const JobberProfileMenuModal = ({ isOpen, onClose, data }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    mutate: blockJobber,
    isSuccess: isBlockSuccess,
    isLoading,
  } = usePutBlockJobber();

  const handleBlock = () => {
    blockJobber({ id: data.jobber.id });
  };

  useEffect(() => {
    if (isBlockSuccess) {
      setShowConfirmation(false);
      onClose();
    }
  }, [isBlockSuccess]);

  if (showConfirmation) {
    return (
      <Modal.Small
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <Modal.Item.Header onClose={() => setShowConfirmation(false)}>
          <Modal.Item.Title>
            {polyglot.t("jobber_profile.block_confirmation_title_first_name", {
              first_name: data.jobber.first_name,
            })}
          </Modal.Item.Title>
        </Modal.Item.Header>
        <Modal.Item.Wrapper>
          <Body16>
            {polyglot.t("jobber_profile.block_confirmation_text_first_name", {
              first_name: data.jobber.first_name,
            })}
          </Body16>
        </Modal.Item.Wrapper>
        <Modal.Item.Footer>
          <Button.Medium
            onClick={() => setShowConfirmation(false)}
            kind={BUTTON.KIND.SECONDARY}
          >
            {polyglot.t("common.cancel")}
          </Button.Medium>
          <Button.Medium onClick={handleBlock} isLoading={isLoading}>
            {polyglot.t("common.confirm")}
          </Button.Medium>
        </Modal.Item.Footer>
      </Modal.Small>
    );
  }

  return (
    <Modal.Small isOpen={isOpen} onClose={onClose}>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>Menu</Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <List.Item
          chevron
          onClick={() => setShowConfirmation(true)}
          LeftComponent={() => (
            <Icon.Medium name="user-times" color={colors.danger} />
          )}
        >
          <Body16 color={colors.danger}>
            {polyglot.t("jobber_profile.block_first_name", {
              first_name: data.jobber.first_name,
            })}
          </Body16>
        </List.Item>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};

export default JobberProfileMenuModal;
