import { Avatar, Block, List, NoResult, Spinner } from "@/components";
import PageTitle from "../PageTitle";
import polyglot from "@/utils/polyglot";
import { useGetBlockedJobbers } from "@/modules/routes/blocked-jobbers-routes";
import { sizes, spacings } from "@/assets/themes";
import UserPreview from "@/pages-admin/UserPreview";
import { JOBBER } from "@/pages-admin/UserPreview/config";
import { DASHBOARD_SMALL_CONTAINER } from "@/utils";

const BlockedJobbers = () => {
  const { data, isLoading } = useGetBlockedJobbers();
  return (
    <Block maxWidth={DASHBOARD_SMALL_CONTAINER} width="100%">
      <PageTitle>{polyglot.t("routes.blocked_jobbers")}</PageTitle>
      {!isLoading ? (
        <Block marginTop={spacings.m}>
          {data?.blocked_jobbers?.length === 0 ? (
            <NoResult
              title={polyglot.t("blocked_jobbers.empty_title")}
              subtitle={polyglot.t("blocked_jobbers.empty_subtitle")}
            />
          ) : (
            <>
              {data.blocked_jobbers.map((jobber) => (
                <UserPreview id={jobber.id} kind={JOBBER}>
                  <List.Item
                    key={jobber.id}
                    href={jobber.url}
                    chevron
                    divider
                    LeftComponent={() => (
                      <Avatar
                        src={jobber.avatar}
                        size={sizes.size48}
                        isTopJobber={jobber.is_top_jobber}
                        name={jobber.first_name}
                      />
                    )}
                  >
                    {jobber.first_name}
                  </List.Item>
                </UserPreview>
              ))}
            </>
          )}
        </Block>
      ) : (
        <Block marginY={spacings.xxl} display="flex" justifyContent="center">
          <Spinner.Medium />
        </Block>
      )}
    </Block>
  );
};

export default BlockedJobbers;
