import { useEffect, useState } from "react";
import { colors, radius, sizes, spacings } from "@/assets/themes";
import { Block, Button, FilePicker } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import {
  useDeletePhoto,
  usePostPhotoToToken,
} from "@/modules/routes/job-routes";
import { MAX_JOB_PHOTOS } from "@/utils";

const EnhancedFilePicker = ({
  size,
  value,
  token: _token,
  onChange,
  onRemove,
  ...rest
}) => {
  const uploadPhoto = usePostPhotoToToken();
  const removePhoto = useDeletePhoto();
  const [upload, setUpload] = useState(value);
  const [token, setToken] = useState(_token);

  const handleRemove = () => {
    removePhoto.mutate({ id: token });
    if (onChange) onRemove({ token });
    setUpload(null);
    setToken(null);
  };

  useEffect(() => {
    if (uploadPhoto.isSuccess) {
      const token = uploadPhoto.data.data.data.id;
      const file = uploadPhoto.variables.attachment;
      setUpload(file);
      setToken(token);
      if (onChange) onChange({ token, file });
    }
  }, [uploadPhoto.isSuccess]);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    uploadPhoto.mutate({
      attachment: file,
    });
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.value = "";
  };

  return (
    <FilePicker
      value={upload}
      style={{ width: size, height: size }}
      accept="image/jpeg,image/png"
      title={false}
      progressAmount={uploadPhoto.progress}
      css={`
        margin-right: ${spacings.s};
        margin-bottom: ${spacings.s};
      `}
      onRemove={handleRemove}
      onChange={handleFileChange}
      {...rest}
    />
  );
};

// const setDefaultValue = (defaultValues) => {
//   const arr = new Array(MAX_JOB_PHOTOS).fill("");
//   defaultValues.forEach((dv, index) => {
//     arr[index] = dv;
//   });
// };

const PhotosUpload = ({
  size = sizes.size96,
  onChange,
  onDelete,
  max = MAX_JOB_PHOTOS,
  value = [],
  initialSrc: _initialSrc = [],
  tokens: _tokens = [],
  ...rest
}) => {
  const [uploads, setUploads] = useState(value);
  const [tokens, setTokens] = useState(_tokens);
  const [initialSrc, setInitialSrc] = useState(_initialSrc);

  const handleFileChange = ({ token, file }) => {
    setTokens((s) => [...s, token]);
    setUploads((s) => [...s, file]);
  };

  useEffect(() => {
    if (onChange) onChange({ tokens, uploads });
  }, [tokens]);

  const handleFileRemove = ({ token }) => {
    const index = tokens.findIndex((up) => up === token);
    setTokens((s) => s.filter((_, i) => i !== index));
    setUploads((s) => s.filter((_, i) => i !== index));
  };

  const handleDelete = (id) => {
    if (onDelete) onDelete(id);
    setInitialSrc((s) => s.filter(({ id: _id }) => _id !== id));
  };

  return (
    <Block
      display="flex"
      flexWrap="wrap"
      marginBottom={`calc(${spacings.s} * -1)`}
    >
      {initialSrc.map(({ thumbnail, id }, index) => (
        <Block position="relative" marginRight={spacings.s}>
          <img
            key={`photos-job-${index}`}
            src={thumbnail}
            style={{
              borderRadius: radius.m,
              width: size,
              height: size,
              objectFit: "cover",
            }}
            alt=""
          />
          {id && (
            <Block position="absolute" top="0" right="0" padding={spacings.s}>
              <Button.Small
                kind={BUTTON.KIND.MINIMAL}
                onClick={() => handleDelete(id)}
                shape={BUTTON.SHAPE.CIRCLE}
                css={`
                  margin-left: auto;
                  background: ${colors.overlayBackdrop}!important;
                  color: ${colors.onColor}!important;
                `}
              >
                <i className="icon-times" />
              </Button.Small>
            </Block>
          )}
        </Block>
      ))}
      {new Array(max - initialSrc.length).fill("").map((_, index) => (
        <EnhancedFilePicker
          key={`job-photos-file-picker-${index}`}
          value={uploads[index]}
          token={tokens[index]}
          onChange={handleFileChange}
          onRemove={handleFileRemove}
          size={size}
          {...rest}
        />
      ))}
    </Block>
  );
};

export default PhotosUpload;
