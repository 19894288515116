import { useFetch, useInfiniteFetch } from "@/modules/hooks";

export const useGetJobberProfile = ({ id, skill, category, offer_id }) =>
  useFetch(`users/${id}/jobber_profile`, {
    refetchOnWindowFocus: false,
    params: { skill, category, offer_id },
  });

export const useGetRatings = ({ id, rates_category, rate }) =>
  useInfiniteFetch(
    `/users/${id}/rates`,
    { rates_category, rate },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      queryKey: [`/users/${id}/rates`, { rates_category, rate }],
    }
  );
