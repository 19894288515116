import { useState } from "react";
import { colors, radius, sizes, spacings } from "../../assets/themes";
import { getValidationOfferUrl } from "../../pages/Job/utils";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import List from "../List";
import Rating from "../Rating";
import Shape from "../Shape";
import { LIST, LIST_HEADER, SHAPE, TAG } from "../Styles/variants";
import Tag from "../Tag";
import { Body14, Body16 } from "../Text";
import EditRatingModal from "./EditRatingModal";
import polyglot from "@/utils/polyglot";
import JobberProfileGallery from "@/pages/JobberProfile/JobberProfileGallery";

const OfferJobberRating = ({
  id,
  rate,
  rateable,
  openInModal,
  jobber,
  renderHeader,
  onSuccess,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const rightComponent = rate?.editable
    ? () => (
        <Button.Link
          onClick={() => setModalIsOpen(true)}
          css={`
            padding-left: 0;
            padding-right: 0;
          `}
        >
          {polyglot.t("common.edit_my_rating")}
        </Button.Link>
      )
    : null;

  const headerProps = {
    kind: LIST_HEADER.KIND.SUBTITLE,
    RightComponent: rightComponent,
    children: polyglot.t("commin.rating"),
  };

  const defaultHeader = (
    <Block display="flex" alignItems="baseline" marginBottom={spacings.s}>
      <Body14
        color={colors.muted}
        css={`
          flex: 1;
        `}
      >
        {headerProps.children}
      </Body14>
      {headerProps.RightComponent && headerProps.RightComponent()}
    </Block>
  );

  return (
    <>
      <EditRatingModal
        isOpen={modalIsOpen}
        isNew={!rate}
        onClose={() => setModalIsOpen(false)}
        onSuccess={onSuccess}
        rate={rate}
        jobber={jobber}
        id={id}
      />
      {/* Show header only if a rate is avalailable (for edit) or if in transaction details */}
      {(rate || renderHeader) && (
        <>{renderHeader ? renderHeader(headerProps) : defaultHeader}</>
      )}
      {rate && (
        <Block
          backgroundColor={colors.backgroundLight}
          padding={spacings.sm}
          borderRadius={radius.ml}
        >
          <Block display="flex" justifyContent="space-between">
            <Body14 color={colors.muted}>{rate.created_at}</Body14>
            <Rating rate={rate.rate} />
          </Block>
          {rate.photos?.length > 0 && (
            <Block
              marginTop={spacings.s}
              marginBottom={spacings.s}
              display="flex"
              alignItems="center"
              gap={spacings.s}
            >
              <JobberProfileGallery rates={[{ ...rate }]}>
                {rate.photos.map((item, index) => (
                  <img
                    key={`${item.thumbnail}-${index}-photos-item-rate`}
                    src={item.thumbnail}
                    alt={`${item.job_title} photos`}
                    srcSet={item.large}
                    style={{
                      width: sizes.size48,
                      height: sizes.size48,
                      objectFit: "cover",
                      borderRadius: radius.m,
                    }}
                  />
                ))}
              </JobberProfileGallery>
            </Block>
          )}
          <Body16
            css={`
              word-wrap: break-word;
              word-break: break-word;
            `}
          >
            {rate.comment}
          </Body16>
          {rate.badge?.value && (
            <Block marginTop={spacings.s} display="inline-flex">
              <Tag.Small
                kind={TAG.KIND.WHITE}
                css={`
                  padding-left: ${spacings.xs};
                `}
                LeftComponent={() => (
                  <Block
                    width={sizes.size16}
                    height={sizes.size16}
                    borderRadius={radius.circle}
                    backgroundImage={`url(${rate.badge.image})`}
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                  />
                )}
              >
                {rate.badge.value}
              </Tag.Small>
            </Block>
          )}
        </Block>
      )}
      {rateable && (
        <List.Item
          divider={false}
          withGutters
          shape={LIST.SHAPE.ROUND}
          css={`
            background-color: ${colors.backgroundLight};
          `}
          LeftComponent={() => (
            <Shape.Small
              shape={SHAPE.SHAPE.CIRCLE}
              backgroundColor={colors.warning}
              color={colors.warningDark}
            >
              <Icon.Medium name="star-solid" />
            </Shape.Small>
          )}
          chevron
          href={openInModal ? undefined : getValidationOfferUrl(id)}
          onClick={openInModal ? () => setModalIsOpen(true) : undefined}
        >
          <Body16 strong>
            {polyglot.t("job.add_rating_to_first_name", {
              first_name: jobber.first_name,
            })}
          </Body16>
          <Body14>{polyglot.t("job.add_rating_description")}</Body14>
        </List.Item>
      )}
    </>
  );
};
export default OfferJobberRating;
