import React, { useState, forwardRef, useImperativeHandle } from "react";
import { ButtonBase } from "../Styles/Base";
import ImageGalleryModal from "./ImageGalleryModal";

const ImageGallery = forwardRef(
  ({ children, className, renderImagesOverlay }, ref) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [initialIndex, setInitialIndex] = useState(null);

    useImperativeHandle(ref, () => ({
      open: (index) => {
        setInitialIndex(index);
        setModalIsOpen(true);
      },
    }));

    const handleImageClick = (index) => {
      setInitialIndex(index);
      setModalIsOpen(true);
    };

    const handleCloseModal = () => {
      setInitialIndex(null);
      setModalIsOpen(false);
    };

    return (
      <>
        {React.Children.map(children, (child, index) => {
          if (child?.props?.hidden) {
            return null;
          }
          return (
            <ButtonBase
              type="button"
              onClick={() => handleImageClick(index)}
              disabled={child?.props?.disabled}
              css={`
                cursor: ${child?.props?.disabled
                  ? "wait"
                  : "zoom-in"}!important;
              `}
              className={className}
            >
              {React.cloneElement(child)}
            </ButtonBase>
          );
        })}
        {modalIsOpen && (
          <ImageGalleryModal
            isOpen={modalIsOpen}
            initialIndex={initialIndex}
            onClose={handleCloseModal}
            renderImagesOverlay={renderImagesOverlay}
          >
            {children}
          </ImageGalleryModal>
        )}
      </>
    );
  }
);

export default ImageGallery;
