import { useEffect, useRef, useState, useCallback } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Block,
  Body14,
  Body18,
  Button,
  Carousel,
  Display3,
  Icon,
  InfiniteScrollEnhancer,
  Info,
  List,
  Modal,
  Popover,
  Radio,
  Spinner,
} from "@/components";
import { getRate } from "@/components/Rating";
import { BUTTON, LIST, POPOVER } from "@/components/Styles/variants";
import { useGetRatings } from "@/modules/routes/jobber-profile-routes";
import polyglot from "@/utils/polyglot";
import { borderWidth, colors, radius, sizes, spacings } from "@/assets/themes";
import RateItem from "./RateItem";
import RatesProgression from "./RatesProgression";
import { useBreakpoints } from "@/modules/hooks";
import JobberProfileGallery from "./JobberProfileGallery";

const JobberRatingsModal = ({ data }) => {
  const breakpoints = useBreakpoints();
  const scrollableRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [category, setCategory] = useState(null);
  const [hasAutoScroll, setHasAutoScroll] = useState(false);
  const [rate, setRate] = useState(
    new URLSearchParams(location.search).get("ratings_rate")
      ? Number(new URLSearchParams(location.search).get("ratings_rate"))
      : null
  );

  const getRatings = useGetRatings({
    id: data.jobber.id,
    rate,
    rates_category: category,
  });

  const handleClose = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    ["ratings", "ratings_rate", "ratings_id"].forEach((param) =>
      searchParams.delete(param)
    );
    history.replace({ search: searchParams.toString() });
  }, [location.search, history]);

  const handleRateChange = useCallback(({ rate }) => {
    setRate(rate);
  }, []);

  const handleCategoryChange = useCallback((newCategory, { close }) => {
    setCategory(newCategory);
    close();
  }, []);

  useEffect(() => {
    getRatings.refetch();
  }, [category, rate]);

  useEffect(() => {
    if (!getRatings.isLoading) {
      const searchParams = new URLSearchParams(location.search);
      const rateId = searchParams.get("ratings_id");
      if (rateId) {
        const rateElement = document.getElementById(`rate-${rateId}`);
        if (rateElement && !hasAutoScroll) {
          requestAnimationFrame(() => {
            rateElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
              container: scrollableRef.current,
            });
            setHasAutoScroll(true);
          });
        }
      }
    }
  }, [getRatings.isLoading, location.search]);

  const renderCategoryFilter = () => (
    <Popover.Enhancer
      trigger={POPOVER.TRIGGER_TYPE.CLICK}
      content={({ close }) => (
        <Popover.Elem.Menu
          css={`
            min-width: 320px;
          `}
        >
          <List.Group>
            {[null, ...data.rating_categories_available].map((categoryId) => (
              <List.Item
                key={`jobber-profile-category-filter-${categoryId}`}
                withGutters
                size={LIST.SIZE.COMPACT}
                RightComponent={() => (
                  <Radio checked={categoryId === category} />
                )}
                onClick={() => handleCategoryChange(categoryId, { close })}
              >
                {categoryId
                  ? polyglot.t(`categories.${categoryId}.name`)
                  : polyglot.t("jobber_profile.all_categories_filter")}
              </List.Item>
            ))}
          </List.Group>
        </Popover.Elem.Menu>
      )}
    >
      <Button.Small
        shape={BUTTON.SHAPE.PILL}
        kind={BUTTON.KIND.MINIMAL}
        css={`
          border: solid ${borderWidth.s} ${colors.border};
        `}
        RightComponent={() => <Icon.Medium name="dropdown" />}
      >
        {category
          ? polyglot.t(`categories.${category}.name`)
          : polyglot.t("jobber_profile.all_categories_filter")}
      </Button.Small>
    </Popover.Enhancer>
  );

  const handleGoToRating = ({ id, rate }) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("ratings", "true");
    if (id) searchParams.set("ratings_id", id);
    if (rate) searchParams.set("ratings_rate", rate);
    history.replace({ search: searchParams.toString() });
  };

  const renderRatesList = (ratesData) => (
    <Block spaceY={spacings.m}>
      {ratesData.rates.length > 0 ? (
        ratesData.rates.map((rate) => (
          <div
            id={`rate-${rate.id}`}
            key={`jobber-profile-rate-${rate.id}`}
            css="scroll-margin: 55px;"
          >
            <RateItem {...rate} />
          </div>
        ))
      ) : (
        <Body14 color={colors.muted}>
          {polyglot.t("jobber_profile.no_ratings")}
        </Body14>
      )}
    </Block>
  );

  return (
    <Modal.Small
      fullScreenOnMobile
      isOpen
      onClose={handleClose}
      css={`
        > div:last-of-type {
          align-items: flex-start;
          > div {
            margin-top: ${breakpoints.get({ xs: 0, sm: "4vh" })};
            margin-bottom: ${breakpoints.get({ xs: 0, sm: "4vh" })};
            max-height: ${breakpoints.get({ xs: "inherit", sm: "92vh" })};
            display: block;
            overflow-y: ${breakpoints.get({ xs: "initial", sm: "auto" })};
          }
        }
      `}
    >
      <Modal.Item.Header
        css={`
          position: sticky;
          top: 0;
          z-index: 9;
          background-color: ${colors.background};
          border-bottom: solid ${borderWidth.s} ${colors.border};
          padding-bottom: ${spacings.s};
        `}
        onClose={handleClose}
        navBarContent={() => (
          <Block
            position="absolute"
            left="0"
            right="0"
            textAlign="center"
            pointerEvents="none"
          >
            <Body18 strong align="center">
              {data.jobber.first_name}
            </Body18>
          </Block>
        )}
      />
      <Block overflowY="scroll" ref={scrollableRef}>
        <Modal.Item.Wrapper>
          <Block
            display="flex"
            alignItems="center"
            flexDirection="column"
            color={data.jobber.is_top_jobber ? colors.purple : undefined}
            marginTop={spacings.m}
          >
            <Display3 align="center">
              <Block display="flex" alignItems="center" gap={spacings.s}>
                {getRate(data.jobber.rate)}
                <Icon.XLarge
                  size={sizes.size36}
                  name={data.jobber.is_top_jobber ? "love-solid" : "star-solid"}
                />
              </Block>
            </Display3>
            {data.jobber.is_top_jobber && (
              <>
                <Body18 strong align="center">
                  {polyglot.t("common.top_jobber")}
                </Body18>
                <Body14 color={colors.muted} align="center">
                  {polyglot.t("common.top_jobber_description")}
                </Body14>
              </>
            )}
          </Block>

          <InfiniteScrollEnhancer query={getRatings} unionBy="rates">
            {({ data: ratesData, isLoading, isFetching }) => (
              <>
                <Block marginBottom={spacings.m}>
                  <RatesProgression
                    rates_count_for_rate={
                      ratesData
                        ? ratesData.rates_count_for_rate
                        : data.rates_count_for_rate
                    }
                    onChange={handleRateChange}
                    activeRate={rate}
                  />
                </Block>
                <List.Header RightComponent={renderCategoryFilter}>
                  <Block display="inline-flex" alignItems="center">
                    {polyglot.t("common.rates", {
                      count: ratesData
                        ? ratesData.total_count
                        : data.total_count,
                    })}
                    <Info>{polyglot.t("jobber_profile.ratings_info")}</Info>
                  </Block>
                </List.Header>

                {isLoading && (
                  <Block
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding={spacings.m}
                  >
                    <Spinner.Medium />
                  </Block>
                )}
                {!isLoading && (
                  <>
                    {ratesData.rating_photos?.length > 0 && (
                      <Block
                        paddingY={spacings.m}
                        marginX={`calc(${spacings.m} * -1)`}
                      >
                        <JobberProfileGallery
                          rates={data.rates}
                          rating_photos={ratesData.rating_photos}
                          onClick={handleGoToRating}
                          ImagesContainerWrapper={({ children }) => (
                            <Carousel
                              paddingX={spacings.m}
                              slideToShow={{
                                xs: 2.5,
                                sm: 3.5,
                                md: 3,
                                lg: 3.5,
                                xl: 4,
                              }}
                              gutter={spacings.s}
                            >
                              {children}
                            </Carousel>
                          )}
                        >
                          {({ data }) =>
                            data.map((item, index) => (
                              <img
                                key={`${item.thumbnail}-${index}-photos-item-rate`}
                                src={item.thumbnail}
                                alt={`${item.rate.job_title} photos`}
                                srcSet={item.large}
                                style={{
                                  objectFit: "cover",
                                  aspectRatio: "1/1",
                                  width: "100%",
                                  borderRadius: radius.ml,
                                }}
                              />
                            ))
                          }
                        </JobberProfileGallery>
                      </Block>
                    )}
                    {renderRatesList(ratesData)}
                  </>
                )}
              </>
            )}
          </InfiniteScrollEnhancer>
        </Modal.Item.Wrapper>
      </Block>
    </Modal.Small>
  );
};

export default JobberRatingsModal;
