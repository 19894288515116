import { XSmall, Small, Medium, Large, FullScreen } from "./Modal";
import Header from "./Item/Header";
import Footer from "./Item/Footer";
import Title from "./Item/header/Title";
import Subtitle from "./Item/header/Subtitle";
import Wrapper from "./Item/Wrapper";

export default {
  XSmall,
  Small,
  Medium,
  Large,
  FullScreen,
  Item: { Header, Wrapper, Title, Subtitle, Footer },
};
