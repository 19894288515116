import { useHistory, useLocation } from "react-router-dom";
import { radius, spacings } from "@/assets/themes";
import { Block, Button, Carousel } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import polyglot from "@/utils/polyglot";
import JobberProfileGallery from "./JobberProfileGallery";
import RateItem from "./RateItem";
import RatesProgression from "./RatesProgression";

const JobberRates = ({ data }) => {
  const location = useLocation();
  const history = useHistory();
  const breakpoints = useBreakpoints();

  const handleGoToRating = ({ id, rate }) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("ratings", "true");
    if (id) searchParams.set("ratings_id", id);
    if (rate) searchParams.set("ratings_rate", rate);
    history.replace({ search: searchParams.toString() });
  };

  return (
    <Block spaceY={spacings.m}>
      <RatesProgression
        rates_count_for_rate={data.rates_count_for_rate}
        onChange={handleGoToRating}
      />

      {data.rating_photos?.length > 0 && (
        <JobberProfileGallery
          rates={data.rates}
          rating_photos={data.rating_photos}
          onClick={handleGoToRating}
          ImagesContainerWrapper={({ children }) => (
            <Carousel
              slideToShow={{ xs: 2.5, sm: 3.5, md: 3, lg: 3.5, xl: 4 }}
              gutter={spacings.s}
            >
              {children}
            </Carousel>
          )}
        >
          {({ data }) =>
            data.map((item, index) => (
              <img
                key={`${item.thumbnail}-${index}-photos-item-rate`}
                src={item.thumbnail}
                alt={`${item.rate.job_title} photos`}
                srcSet={item.large}
                style={{
                  objectFit: "cover",
                  aspectRatio: "1/1",
                  width: "100%",
                  borderRadius: radius.ml,
                }}
              />
            ))
          }
        </JobberProfileGallery>
      )}
      {data.rates.length > 0 && (
        <Carousel
          slideToShow={{
            xs: 1.2,
            sm: 1.5,
            md: 1.2,
            xl: 2.5,
          }}
          gutter={spacings.s}
          draggable={breakpoints.get({ xs: true, md: false })}
        >
          {data.rates.map((rate) => (
            <RateItem
              id={rate.id}
              key={`jobber-profile-rate-card-${rate.id}`}
              rate={rate.rate}
              onClick={() => {
                handleGoToRating({ id: rate.id });
              }}
              customer={rate.customer}
              job_title={rate.job_title}
              comment={rate.comment}
              created_at={rate.created_at}
              photos={rate.photos}
              numberOfLines={3}
              isCard
            />
          ))}
        </Carousel>
      )}
      {data.jobber.rates_count > 0 && (
        <Button.Medium
          kind={BUTTON.KIND.SECONDARY}
          block
          onClick={handleGoToRating}
        >
          {polyglot.t("jobber_profile.see_ratings")} ({data.total_count})
        </Button.Medium>
      )}
    </Block>
  );
};

export default JobberRates;
