import styled from "styled-components";
import { colors, spacings } from "@/assets/themes";

const StyledRateItemBackdrop = styled.div`
  position: relative;
  padding: 0 ${spacings.m};
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    background: linear-gradient(${colors.body}, rgba(0, 0, 0, 0));
    z-index: -1;
  }
  p,
  span,
  div {
    color: ${colors.white};
  }
`;

export default StyledRateItemBackdrop;
