import { FastField } from "formik";
import React from "react";
import { colors, spacings } from "../../../../assets/themes";
import {
  Block,
  Body14,
  FormControl,
  Icon,
  Input,
} from "../../../../components";
import polyglot from "../../../../utils/polyglot";
import { CREDIT_CARD_NUMBER_MASK } from "../constants";

const CreditCardNumbersField = () => (
  <FastField name="cardNumber">
    {({ field }) => (
      <FormControl
        label={() => (
          <Block display="flex">
            <span
              css={`
                flex-grow: 1;
              `}
            >
              {polyglot.t("payment_methods.card_number")}
            </span>
            <Block display="flex" alignItems="center">
              <Icon.Medium
                name="lock"
                color={colors.mutedLight}
                css={`
                  margin-right: ${spacings.xs};
                `}
              />
              <Body14
                css={`
                  white-space: nowrap;
                `}
                color={colors.muted}
              >
                {polyglot.t("common.secure_payment")}
              </Body14>
            </Block>
          </Block>
        )}
      >
        <Input
          RightComponent={() => <Icon.Large name="lock" color={colors.muted} />}
          placeholder="0000 0000 0000 0000"
          autoComplete="cc-number"
          inputMode="numeric"
          mask={CREDIT_CARD_NUMBER_MASK}
          {...field}
        />
      </FormControl>
    )}
  </FastField>
);

export default CreditCardNumbersField;
