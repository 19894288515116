import moment from "moment";
import { useState, useMemo, useRef, useEffect } from "react";
import styled from "styled-components";
import { capitalize } from "lodash";
import Carousel from "../Carousel";
import { formatTo, getWeekDays } from "../DatePicker/utils";
import { ButtonBase } from "../Styles/Base";
import { Body14, H5 } from "../Text";
import { borderWidth, colors, radius, sizes, spacings } from "@/assets/themes";
import Skeleton from "../skeleton";
import Block from "../Block";
import { useBreakpoints, useWindowSize } from "@/modules/hooks";

const StyledButton = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${radius.m};
  padding: ${spacings.s};
  margin: ${spacings.xs} 0;
  width: 100%;
  border: ${({ isActive }) =>
    `solid ${borderWidth.m} ${isActive ? colors.primary : colors.border}`};
  background-color: ${({ isActive }) =>
    isActive ? colors.primary : colors.transparent};
  * {
    color: ${({ isActive }) => (isActive ? colors.onColor : colors.body)};
  }
`;

const ScrollableDays = ({
  onChange,
  availabilities,
  active,
  minDate,
  format,
  onCarouselChange,
}) => {
  const { width } = useWindowSize();
  const weekDays = getWeekDays();
  const breakpoints = useBreakpoints();
  const carouselRef = useRef(null);

  const availableDates = useMemo(() => {
    if (!availabilities) return [];
    const minMoment = moment(minDate, format).startOf("day");
    return Object.keys(availabilities)
      .filter(
        (dateString) =>
          !minMoment || moment(dateString, format).isSameOrAfter(minMoment)
      )
      .map((dateString) => dateString);
  }, [availabilities, minDate]);

  const activeIndex = useMemo(
    () =>
      availableDates.findIndex((date) =>
        moment(date, format).isSame(moment(active, format), "day")
      ),
    [availableDates, active]
  );

  useEffect(() => {
    if (
      active &&
      carouselRef.current &&
      availableDates.length > 0 &&
      width > 0
    ) {
      if (activeIndex !== -1) {
        carouselRef.current?.goTo(activeIndex);
      }
    }
  }, [availableDates, width]);

  return (
    <>
      {availableDates.length > 0 ? (
        <Carousel
          initialIndex={activeIndex}
          ref={carouselRef}
          onChange={onCarouselChange}
          slideToShow={{ xs: 4, md: 6, lg: 7 }}
          slideToScroll={{ xs: 2, md: 3 }}
          gutter={spacings.s}
        >
          {availableDates.map((date, index) => {
            const weekdayShort = formatTo(date, "YYYY-MM-DD", "ddd").replace(
              ".",
              ""
            );

            const dayNumber = formatTo(date, "YYYY-MM-DD", "D");
            const month = formatTo(date, "YYYY-MM-DD", "MMM");

            return (
              <StyledButton
                key={index}
                type="button"
                onClick={() => onChange(date)}
                isActive={active && moment(active, format).isSame(date, "day")}
              >
                <Body14 strong>{weekdayShort}</Body14>
                <H5>{dayNumber}</H5>
                <Body14>{month}</Body14>
              </StyledButton>
            );
          })}
        </Carousel>
      ) : (
        <Skeleton.Group>
          <Block display="flex" gap={spacings.s}>
            {new Array(breakpoints.get({ xs: 4, md: 6, lg: 7 }))
              .fill("")
              .map((_, index) => (
                <Skeleton
                  height={sizes.size96}
                  width="100%"
                  key={`placeholder-days-${index}`}
                />
              ))}
          </Block>
        </Skeleton.Group>
      )}
    </>
  );
};

export default ScrollableDays;
