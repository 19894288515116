import { useCallback, useMemo } from "react";
import { spacings } from "../../assets/themes";
import {
  Block,
  Breadcrumb,
  Button,
  Container,
  Flow,
  Icon,
  Link,
  Spinner,
  Steps,
} from "../../components";
import { STEPS } from "../../components/Styles/variants";
import { useBreakpoints } from "../../modules/hooks";
import { useGetReview } from "../../modules/routes";
import polyglot from "../../utils/polyglot";
import { DASHBOARD_PATH } from "../dashboard/config/routes";
import JobberTaxes from "../dashboard/taxesCredit/JobberTaxes";
import ConfirmPayment from "./ConfirmPayment";
import JobberRating from "./JobberRating";
import ReviewJobCard from "./ReviewJobCard";

const ReviewProgress = ({ activeIndex, routes }) => {
  const breakpoints = useBreakpoints();
  return (
    <Block
      marginBottom={{ xs: 0, md: spacings.l }}
      display={{ xs: "none", md: "block" }}
    >
      <Container.Small>
        <Steps
          orientation={breakpoints.get({
            xs: STEPS.ORIENTATION.VERTICAL,
            sm: STEPS.ORIENTATION.HORIZONTAL,
          })}
          current={activeIndex}
        >
          {routes.map((route) => (
            <Steps.Step title={route.title} key={`step-${route.path}`} />
          ))}
        </Steps>
      </Container.Small>
    </Block>
  );
};

const JobberReview = ({ offer_id, job }) => {
  const { data, isLoading } = useGetReview({ id: offer_id });

  const getSteps = useCallback(() => {
    if (!isLoading && data) {
      return [
        {
          path: "confirm",
          View: ConfirmPayment,
          title: polyglot.t("job_review.confirm_paiement"),
          customProps: { offer_id, data },
        },
        ...(data?.automatic_declarative || data?.tax_paid
          ? {
              path: "taxes",
              View: JobberTaxes,
              title: polyglot.t("job_review.tax"),
              customProps: {
                id: offer_id,
                hideJobberItem: true,
                initialValues: { from: "flow" },
              },
            }
          : []),
        {
          path: "rate",
          View: JobberRating,
          title: polyglot.t("job_review.rate_jobber"),
          customProps: {
            offer_id,
            rate: data?.rate,
            jobber: data?.offer.jobber,
            badges_list: data?.badges_list,
            improvments_list: data?.improvments_list,
            complaints_list: data?.complaints_list,
            can_add_rating_photos: data?.can_add_rating_photos,
          },
        },
      ];
    }
    return null;
  }, [data?.automatic_declarative, data?.tax_paid]);

  const getInitialStep = () => {
    const params = new URLSearchParams(window.location.search);
    const from = params.get("from");
    // const status = params.get("status");
    if (data?.offer?.state === "paid") {
      if (from === "flow" || (data.automatic_declarative && !data.tax_paid)) {
        return getSteps().findIndex((s) => s.path === "taxes");
      }
      return getSteps().findIndex((s) => s.path === "rate");
    }
    return 0;
  };

  return (
    <div>
      <Container.Small>
        <Block marginTop={spacings.ml}>
          <Block display={{ xs: "none", sm: "block" }}>
            <Breadcrumb>
              <Link href={DASHBOARD_PATH}>{polyglot.t("routes.jobs")}</Link>
              <Link href={job.url}>{job.title}</Link>
              <span>{polyglot.t("job_review.title")}</span>
            </Breadcrumb>
          </Block>
          <Block display={{ xs: "block", sm: "none" }}>
            <Button.Link
              css={`
                padding-left: 0;
                padding-right: 0;
              `}
              href={job.url}
              LeftComponent={() => <Icon.Medium name="arrow-left" />}
            >
              {job.title}
            </Button.Link>
          </Block>
        </Block>
      </Container.Small>
      {getSteps() ? (
        <Block paddingY={{ xs: spacings.m, md: spacings.l }}>
          <Container.Small>
            <Block
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              width="100%"
            >
              <Block marginBottom={{ xs: spacings.l, md: spacings.l }}>
                <ReviewJobCard
                  offer_amount={data.offer.price}
                  jobber={data.offer.jobber}
                  state={data.offer.state}
                />
              </Block>
              <Flow.Router
                routes={getSteps()}
                initialStep={getInitialStep}
                renderProgress={ReviewProgress}
              />
            </Block>
          </Container.Small>
        </Block>
      ) : (
        <Block marginY={spacings.xxl} display="flex" justifyContent="center">
          <Spinner.Medium />
        </Block>
      )}
    </div>
  );
};

export default JobberReview;
