import { useFormikContext } from "formik";
import { sizes } from "@/assets/themes";
import { FormControl } from "@/components";
import polyglot from "@/utils/polyglot";
import PhotosUpload from "../editJob/PhotosUpload";

const RatingPhotos = ({ initialSrc }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleChange = ({ uploads, tokens }) => {
    setFieldValue("uploads", uploads);
    setFieldValue("photos", tokens);
  };

  const handleDelete = (id) => {
    setFieldValue("deleted_photos", [...(values.deleted_photos || []), id]);
  };

  return (
    <div>
      <FormControl label={polyglot.t("rating.photos.label")}>
        <PhotosUpload
          size={sizes.size136}
          onChange={handleChange}
          onDelete={handleDelete}
          tokens={values.photos}
          value={values.uploads}
          initialSrc={initialSrc}
        />
      </FormControl>
    </div>
  );
};

export default RatingPhotos;
