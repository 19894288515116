import { spacings } from "../../assets/themes";
import { useGetJobberRate, useGetJobberRateNew } from "../../modules/routes";
import JobberRating from "../../pages/jobberReview/JobberRating";
import Block from "../Block";
import Modal from "../Modal";
import Spinner from "../Spinner";

const EditRatingModal = ({
  isOpen,
  onClose,
  id,
  rate,
  jobber,
  onSuccess,
  isNew,
}) => {
  const hook = isNew ? useGetJobberRateNew : useGetJobberRate;
  const { data, remove } = hook(
    {
      id,
    },
    { enabled: isOpen }
  );

  const handleSuccess = () => {
    remove();
    if (onSuccess) onSuccess();
    if (onClose) onClose();
  };

  return (
    <Modal.Small fullScreenOnMobile isOpen={isOpen} onClose={onClose}>
      <Modal.Item.Header onClose={onClose} />
      <Modal.Item.Wrapper
        css={`
          overflow-x: hidden;
        `}
      >
        {data ? (
          <JobberRating
            offer_id={id}
            rate={rate}
            can_add_rating_photos={data.can_add_rating_photos}
            extra_rate={data.extra_rate}
            jobber={jobber}
            isEdit={!isNew}
            onSuccess={handleSuccess}
          />
        ) : (
          <Block
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin={spacings.m}
          >
            <Spinner.Medium />
          </Block>
        )}
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};
export default EditRatingModal;
